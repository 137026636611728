/**
 * App Main
 * @returns {Promise<void>}
 */
const main = async () => {
    // Import our CSS
    const Styles = await import(
        /* webpackChunkName: "styles" */
        '../css/app.pcss'
    );

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        components: {
            'hamburger': () => import(/* webpackChunkName: "Hamburger" */ '../vue/Hamburger.vue'),
            'addressedit': () => import(/* webpackChunkName: "AddressEdit" */ '../vue/AddressEdit.vue'),
            'registrationform': () => import(/* webpackChunkName: "RegistrationForm" */ '../vue/RegistrationForm.vue'),
            'editaccountform': () => import(/* webpackChunkName: "EditAccountForm" */ '../vue/EditAccountForm.vue'),
            'verificationform': () => import(/* webpackChunkName: "VerificationForm" */ '../vue/VerificationForm.vue'),
            'sportfinderinput': () => import(/* webpackChunkName: "SportFinderInput" */ '../vue/SportFinderInput.vue'),
            'sportfilter': () => import(/* webpackChunkName: "SportFilter" */ '../vue/SportFilter.vue'),
            'trainingfinder': () => import(/* webpackChunkName: "TrainingFinder" */ '../vue/TrainingFinder.vue'),
            'gallery': () => import(/* webpackChunkName: "Gallery" */ '../vue/Gallery.vue'),
            'tabs': () => import(/* webpackChunkName: "Tabs" */ '../vue/Tabs.vue'),
            'search': () => import(/* webpackChunkName: "Search" */ '../vue/Search.vue'),
            'addtocartvariants': () => import(/* webpackChunkName: "Variants" */ '../vue/addToCart/Variants.vue'),
            'shopsearch': () => import(/* webpackChunkName: "ShopSearch" */ '../vue/ShopSearch.vue'),
            'shoplisting': () => import(/* webpackChunkName: "ShopListing" */ '../vue/ShopListing.vue'),
            'validateticket': () => import(/* webpackChunkName: "ValidateTicket" */ '../vue/ValidateTicket.vue'),
        },
        data: {
        },
        methods: {
        },
        mounted() {
        },
    });

    return vm;
};

/**
 * Execute App main async function
 */
main().then( (vm) => {
});

const lazyLoading = async() => {
  const lazySizes = await import (
    /* webpackChunkName: "lazysizes" */
    'lazysizes'
  );
}

window.addEventListener('load', function() {
  lazyLoading();
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
